//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
  margin-bottom: 3px;
  list-style: none;
  background-color: @breadcrumb-bg;
  border-radius: @border-radius-base;
  text-transform: uppercase;

  > li {
	display: inline-block;
	color: #3C4546;

	+ li:before {
	  content: "@{breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
	  padding: 0px 1px 0px 8px;
	  color: @breadcrumb-color;
	  font-family: 'Glyphicons Halflings';
	  font-size: 8px;
	  position: relative;
	  top: -1px;
	}
  }

  > .active {
	color: @breadcrumb-active-color;
  }
}

.breadcrumb > li > a {
  color: #000;
}
